.container {
  height: 100vh;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hoverContainer {
  height: 145vh;
  width: 100%;
  display: flex;
  align-items:center;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 22%;
  margin:auto;
  z-index: 1000;
}

.hoverer1 {
  margin: auto 0;
  height: 100%;
  width: 11%;
 
}

@media screen and (max-width: 2500px) {
  .hoverContainer {
    top: 5%;
    height: 1100px;
    
  }
}

  @media screen and (max-width: 2000px) {
    .hoverContainer {
      top: 8%;
      height: 900px;
      
    }
}

@media screen and (max-width: 1536px) {
  .hoverContainer {
    top: 20%;
    height: 700px;
    
  }
}

@media screen and (max-width: 1280px) {
  .hoverContainer {
    top: 25%;
    height: 520px;
    
  }
}

@media screen and (max-width: 1024px) {
  .hoverContainer {
    top: 30%;
    height: 480px;
    
  }
}

@media screen and (max-width: 768px) {
  .hoverContainer {
    top: 35%;
    height: 400px;
    
  }
}

@media screen and (max-width: 640px) {
  .hoverContainer {
    top: 38%;
    height: 300px;
    
  }
}
/* .hoverer2 {
  
  height: 100%;
  width: 11%;
  
}
.hoverer3 {

  height: 100%;
  width: 11%;
}
.hoverer4 {
  
  height: 100%;
  width: 10%;
  
}
.hoverer5 {
 
  height: 100%;
  width: 12%;
} */

.backgroundImage {

  margin: auto;
  display: grid;
  text-align: center;
  column-gap: 0;
  width: 100%;
  height: 100%;
  position: relative;

}

.fate1 {
  width: 70%;
  flex: 1 0 18%;
  grid-area: fate;
  margin: 0 auto;
  z-index: 10;

}

.fate2 {
  width: 70%;
  flex: 1 0 18%;
  grid-area: fate;
  margin: 0 auto;
  z-index: 20;
}

.fate3 {
  width: 70%;
  flex: 1 0 18%;
  grid-area: fate;
  margin: 0 auto;
  z-index: 30;
}

.fate4 {
  width: 70%;
  flex: 1 0 18%;
  grid-area: fate;
  margin: 0 auto;
  z-index: 50;
}

.fate5 {
  width: 70%;
  flex: 1 0 18%;
  grid-area: fate;
  margin: 0 auto;
  z-index: 40;
}

.back {
  grid-area: back;
  width: 70%;
  margin: 0 auto;
}
